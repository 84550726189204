<template>
  <div class="one-key-report">
		<div class="tinymce-editor">
			<Editor v-model="htmlContent" :init="init" :disabled="disabled" @onClick="onClick"></Editor>
		</div>
		<div class="ty-btn">
			<el-button type="danger" @click="clear">清空内容</el-button>
			<el-button type="danger" @click="disabled = !disabled">禁用</el-button>
			<el-button type="primary" @click="saveExportWord">生成word</el-button>
		</div>
  </div>
</template>

<script>
import tinymce from "tinymce/tinymce";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/themes/silver";
// // 编辑器插件plugins
import "tinymce/icons/default/icons"; //为了解决icons的问题
import "tinymce/plugins/advlist";
import "tinymce/plugins/anchor";
import "tinymce/plugins/autolink";
import "tinymce/plugins/autosave";
import "tinymce/plugins/charmap";
import "tinymce/plugins/code";
import "tinymce/plugins/colorpicker";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/directionality";
import "tinymce/plugins/fullpage";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/hr";
import "tinymce/plugins/image";
import "tinymce/plugins/link";
import "tinymce/plugins/lists"; // 列表插件
import "tinymce/plugins/media"; // 插入视频插件
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/noneditable";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/paste";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/save";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/tabfocus";
import "tinymce/plugins/tabfocus";
import "tinymce/plugins/textcolor";
import "tinymce/plugins/textpattern";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/wordcount"; // 字数统计插件
import "tinymce/plugins/template";
import "tinymce/plugins/table"; // 插入表格插件

import { exportMethodWord } from '../../../provider/index.js' //导出word公用方法
export default {
  name: "one-key-report",
  components: {Editor},
  props: {
		//传入一个value，使组件支持v-model绑定
		value: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		plugins: {
			type: [String, Array],
			default: "lists image media table textcolor wordcount contextmenu"
		},
		toolbar: {
			type: [String, Array],
			// default: 'undo redo |  formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image media table | removeformat'
			// default:"undo redo |  formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image | removeformat"
			default: "code advlist anchor autolink link autosave charmap lists image table colorpicker textcolor contextmenu directionality fullpage fullscreen hr nonbreaking noneditable imagetools paste pagebreak save searchreplace tabfocus | textcolor textpattern visualblocks visualchars wordcount template bold hr italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | undo redo | link unlink code | removeformat | fontsizeselect | fontselect"
		}
  },
  data() {
		return {
			//初始化配置
			init: {
				language_url: this.$pulick.baseUrl+"tinymce/langs/zh_CN.js",
				language: "zh_CN",
					skin_url: this.$pulick.baseUrl+"tinymce/skins/ui/oxide",
				height: document.body.clientHeight - 150,
				plugins: this.plugins,
				toolbar: this.toolbar,
				branding: false,
				menubar: false,
				font_formats: "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif,Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats",
				fontsize_formats: "8pt 10pt 12pt 14pt 16pt 18pt 19pt 20pt 21pt 22pt 23pt 24pt 26pt 28pt 30pt 36pt",
				//images_upload_url: '/demo/upimg.php',
				//images_upload_base_path: '/',
				//此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
				//如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
				images_upload_handler: (blobInfo, success, failure) => {
					const img = "data:image/jpeg;base64," + blobInfo.base64();
					success(img);
				}
			},
			htmlContent: this.value,
		}
	},
	watch: {
		value(newValue) {
			this.htmlContent = newValue;
		},
		htmlContent(newValue) {
			this.$emit("input", newValue);
		},
	},
	created() {
		//初始默认填写
		this.htmlContent = '<p>&nbsp;</p>'+
			'<p>&nbsp;</p>'+
			'<p>&nbsp;</p>'+
			'<p>&nbsp;</p>'+
			'<h1 style="text-align: center;">'+this.$route.query.name+'<br/>监测报告</h1>'+
			'<p>&nbsp;</p>'+
			'<p>&nbsp;</p>'+
			'<p>&nbsp;</p>'+
			'<p>&nbsp;</p>'+
			'<p>&nbsp;</p>'+
			'<p style="text-align: center;font-size: 20px">'+this.$moment().format("YYYY年MM月DD日")+'</p>'+
			'<p>&nbsp;</p>'+
			'<p>&nbsp;</p>'+
			'<p>&nbsp;</p>'+
			'<p>&nbsp;</p>'+
			'<p>&nbsp;</p>'+
			'<p>&nbsp;</p>'+
			'<p>&nbsp;</p>'+
			'<p>&emsp;&emsp;截止'+this.$moment().format("YYYY年MM月DD日")+',系统监测发现'+this.$route.query.name+'主要情况如下。</p>'+
			'<p>&nbsp;</p>';
		this.titleOne();//一、广告主基本信息
	},
	mounted() {
		tinymce.init({});
	},
	methods: {
		//添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
		//需要什么事件可以自己增加
		onClick(e) {
			this.$emit("onClick", e, tinymce);
		},
		//可以添加一些自己的自定义事件，如清空内容
		clear() {
			this.htmlContent = "";
		},
		//一、经营主体简介
		titleOne() {
			this.$provider.get('/wg-ifrRisk/report/getBaseInfo',{
				params:{
					companyId: this.$route.query.id
				}
			}).then(res=>{
				let data = res.data;
				if (data != null) {
					let html1 = '<h3><strong>一、经营主体简介</strong></h3>'+
					'<p>&emsp;&emsp;'+data.companyName+'主要从事'+data.industry+'，法定代表人为'+data.legalPersonName+'，注册资本为'+data.regCapital+'，其注册地址为'+data.regLocation+'。</p>';
					this.htmlContent += html1;
					this.titleTwo(); //二、广告发布媒体信息
				} else {
					let html1 = '<h3><strong>一、经营主体简介</strong></h3>'+
					'<p>&nbsp;</p>';
					this.htmlContent += html1;
					this.titleTwo(); //二、广告发布媒体信息
				}
			})
		},
		//二、广告发布媒体信息
		titleTwo() {
			this.$provider.get('/wg-ifrRisk/report/getMainInfo',{
				params:{
					companyId: this.$route.query.id
				}
			}).then(res=>{
				let data = res.data;
				let gudongDa = data.shareholdList;
				let suSongDa = data.lawsuitList;
				let gaoGuanDa = data.managerList;
				let faRenDa = data.legalList;
				let html2 = '<p></p><h3><strong>二、经营主体风险</strong></h3>'+
					'<p>&nbsp;&nbsp;&nbsp;&nbsp;系统监测发现'+this.$route.query.name+'近一年股东变更'+gudongDa.length+'次。详见下表：</p>'+
					'<table border="1" style="width:100%;border-collapse: collapse;"><tbody><tr><td style="width:20%;text-align:center;">变更时间</td><td style="width:40%;text-align:center;">变更前</td><td style="width:40%;text-align:center;">变更后</td></tr>';
				if (gudongDa.length != 0) {
					for (var i = 0; i < gudongDa.length; i++) {
						html2 += '<tr><td style="text-align:center;">'+gudongDa[i].changeTime+'</td><td style="text-align:center;">'+gudongDa[i].contentBefore+'</td><td style="text-align:center;">'+gudongDa[i].contentAfter+'</td></tr>';
					}
					html2 += '</tbody></table><p></p>';
				} else {
					html2 += '</tbody></table><p></p>';
				}
				html2 += '<p>&nbsp;&nbsp;&nbsp;&nbsp;系统监测发现'+this.$route.query.name+'近一年高管变更'+gaoGuanDa.length+'次。详见下表：</p>'+
					'<table border="1" style="width:100%;border-collapse: collapse;"><tbody><tr><td style="width:20%;text-align:center;">变更时间</td><td style="width:40%;text-align:center;">变更前</td><td style="width:40%;text-align:center;">变更后</td></tr>';
				if (gaoGuanDa.length != 0) {
					for (var j = 0; j < gaoGuanDa.length; j++) {
						html2 += '<tr><td style="text-align:center;">'+gaoGuanDa[j].changeTime+'</td><td style="text-align:center;">'+gaoGuanDa[j].contentBefore+'</td><td style="text-align:center;">'+gaoGuanDa[j].contentAfter+'</td></tr>';
					}
					html2 += '</tbody></table><p></p>';
				} else {
					html2 += '</tbody></table><p></p>';
				}
				html2 += '<p>&nbsp;&nbsp;&nbsp;&nbsp;系统监测发现'+this.$route.query.name+'近一年法人变更'+faRenDa.length+'次。详见下表：</p>'+
					'<table border="1" style="width:100%;border-collapse: collapse;"><tbody><tr><td style="width:20%;text-align:center;">变更时间</td><td style="width:40%;text-align:center;">变更前</td><td style="width:40%;text-align:center;">变更后</td></tr>';
				if (faRenDa.length != 0) {
					for (var k = 0; k < faRenDa.length; k++) {
						html2 += '<tr><td style="text-align:center;">'+faRenDa[k].changeTime+'</td><td style="text-align:center;">'+faRenDa[k].contentBefore+'</td><td style="text-align:center;">'+faRenDa[k].contentAfter+'</td></tr>';
					}
					html2 += '</tbody></table><p></p>';
				} else {
					html2 += '</tbody></table><p></p>';
				}
				html2 += '<p>&nbsp;&nbsp;&nbsp;&nbsp;系统监测发现'+this.$route.query.name+'近一年诉讼频繁，达'+suSongDa.length+'次。详见下表：</p>'+
					'<table border="1" style="width:100%;border-collapse: collapse;"><tbody><tr><td style="width:15%;text-align:center;">裁决日期</td><td style="width:40%;text-align:center;">案件名称</td><td style="width:15%;text-align:center;">案由</td><td style="width:20%;text-align:center;">法院</td></tr>';
				if (suSongDa.length != 0) {
					for (var l = 0; l < suSongDa.length; l++) {
						html2 += '<tr><td style="text-align:center;">'+suSongDa[l].publishDate+'</td><td style="text-align:center;">'+suSongDa[l].title+'</td><td style="text-align:center;">'+suSongDa[l].caseReason+'</td><td style="text-align:center;">'+suSongDa[l].courtName+'</td></tr>'; 
					}
					html2 += '</tbody></table><p></p>';
				} else {
					html2 += '</tbody></table><p></p>';
				}
				this.htmlContent += html2;
				this.titleThree(); //三、广告内容基本信息
			})
		},
		//三、广告内容基本信息
		titleThree() {
			this.$provider.get('/wg-ifrRisk/report/getRelationProblemList',{
				params:{
					companyId: this.$route.query.id
				}
			}).then(res=>{
				let titleThreeCompany = res.data;
				let html3 = '<h3><strong>三、经营主体关联分析</strong></h3>'+
					'<h4><strong>&emsp;&emsp;（一）关联问题公司</strong></h4>'+
					'<p>&emsp;&emsp;系统监测发现，与'+this.$route.query.name+'存在问题关联关系的公司共'+titleThreeCompany.length+'家。详见下表：</p>' +
					'<table border="1" style="width:100%;border-collapse: collapse;"><tbody><tr><td style="width:60%;text-align:center;">关联企业名称</td><td style="width:20%;text-align:center;">关联关系类型</td><td style="width:20%;text-align:center;">问题描述</td></tr>';
				if (titleThreeCompany.length != 0) {
					for (var i = 0; i < titleThreeCompany.length; i++) {
						html3 += '<tr><td style="text-align:center;">'+titleThreeCompany[i].lineInfo+'</td><td style="text-align:center;">'+titleThreeCompany[i].lineType+'</td><td style="text-align:center;">'+titleThreeCompany[i].requestDesc+'</td></tr>';
					}
					html3 += '</tbody></table><p></p>';
				} else {
					html3 += '</tbody></table>';
				}
				this.htmlContent += html3;
				this.titleFour(); //四、业务风险分析
			})
		},
		//四、业务风险分析     
		titleFour() {
			this.$provider.get('/wg-ifrRisk/report/getBusiRiskList',{
				params:{
					companyId: this.$route.query.id
				}
			}).then(res=>{
				let titleFour = res.data;
				let html4 = '<p></p><h3><strong>四、业务风险分析</strong></h3>'+
					'<p>&emsp;&emsp;系统监测发现'+this.$route.query.name+'当前业务风险主要为<span>';
				if (titleFour.length != 0) {
					for (var i = 0; i < titleFour.length; i++) {
						html4 += titleFour[i].tagName + "、";
					}
					html4 += '</span></p>'+
					'<p style="width:100%">';
					for (var j = 0; j < titleFour.length; j++) {
						html4 += '<h4><strong>&emsp;&emsp;（'+this.number_zhuanhua(j+1)+'）'+titleFour[j].tagName+
							'</strong></h4><p>&emsp;&emsp;&emsp;&emsp;<strong>风险信息：</strong><span>'+titleFour[j].desc+
							'</span></p><p>&emsp;&emsp;&emsp;&emsp;<strong>风险来源：</strong><span>'+titleFour[j].url+'</span></p>';
					}
				}
				this.htmlContent += html4;
				this.titleFive(); //四、负面舆情分析
			})
		},
		//五、负面舆情分析
		titleFive() {
			this.$provider.get('/wg-ifrRisk/report/getSentimentList',{
				params:{
					companyId: this.$route.query.id
				}
			}).then(res=>{
				let titleFive = res.data;
				let html5 = '<p></p><h3><strong>五、负面舆情分析</strong></h3>'+
					'<p>&emsp;&emsp;系统监测发现'+this.$route.query.name+'当前负面舆情主要为<span>';
				if (titleFive.length != 0) {
					for (var k = 0; k < titleFive.length; k++) {
						html5 += titleFive[k].tagName+"、";
					}
					html5 += '</span></p>'+
					'<p style="width:100%">';
					for (var l = 0; l < titleFive.length; l++) {
						html5 += '<h4><strong>&emsp;&emsp;（' + this.number_zhuanhua(l + 1) + '）'+titleFive[l].tagName+
							'</strong></h4><p>&emsp;&emsp;&emsp;&emsp;<strong>风险信息：</strong><span>'+titleFive[l].desc+
							'</span></p><p>&emsp;&emsp;&emsp;&emsp;<strong>风险来源：</strong><span>'+titleFive[l].url+'</span></p>';
					}
					html5 += '</p>';
				}
				this.htmlContent += html5;
			})
		},
		//保存生成word文档
		saveExportWord() {
			let myObj = {
				method: 'post',
				url: this.$pulick.urlStr + '/wg-ifrRisk/report/exportWord',
				fileName: this.$route.query.name,
				params: {
					'companyName':this.$route.query.name,
					'data':this.htmlContent
				} 
			}
			exportMethodWord(myObj);
		},
		number_zhuanhua(num) {
			let numBig = '';
			if (num == 1) {
				numBig = "一";
			} else if (num == 2) {
				numBig = "二";
			} else if (num == 3) {
				numBig = "三";
			} else if (num == 4) {
				numBig = "四";
			} else if (num == 5) {
				numBig = "五";
			} else if (num == 6) {
				numBig = "六";
			} else if (num == 7) {
				numBig = "七";
			} else if (num == 8) {
				numBig = "八";
			} else if (num == 9) {
				numBig = "九";
			} else if (num == 10) {
				numBig = "十";
			} else if (num == 11) {
				numBig = "十一";
			} else if (num == 12) {
				numBig = "十二";
			} else if (num == 13) {
				numBig = "十三";
			} else if (num == 14) {
				numBig = "十四";
			} else if (num == 15) {
				numBig = "十五";
			} else if (num == 16) {
				numBig = "十六";
			} else if (num == 17) {
				numBig = "十七";
			} else if (num == 18) {
				numBig = "十八";
			} else if (num == 19) {
				numBig = "十九";
			} else if (num == 20) {
				numBig = "二十";
			}
			return numBig;
		}
  },
};
</script>

<style lang="stylus" scoped="scoped"></style>
